@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
  color: rgb(200, 230, 70);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #252525; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #FF9000; 
}